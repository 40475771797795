<template>
  <div>
    <SlideUnlock
      v-if="displayable"
      name="test-unlocker"
      text="Unlock: $0.000"
      :success-text="successText"
      @completed="reset"
    />
  </div>
</template>

<script setup>
import { computed, nextTick, ref } from "vue";
import SlideUnlock from "vue-slide-unlock";

const displayable = ref(true);
const unlocked = ref(false);
const successText = computed(() => {
  if (unlocked.value) {
    return "Success! Resetting...";
  } else {
    return "Unlocking...";
  }
});

function reset() {
  setTimeout(async () => {
    unlocked.value = true;

    setTimeout(async () => {
      displayable.value = false;

      await nextTick();

      displayable.value = true;
    }, 2000);
  }, 2000);
}
</script>
